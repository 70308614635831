.footerContainer {
    background-color: #333;
    color: #eee;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footerContainer .block {
    font-family: 'SFNS Display Thin';
    text-align: center;
    margin: 1em;
    width: 75%;
    display: flex;
    flex-direction: column;
}

.footerContainer p {
    margin: auto;
    opacity: 0.5;
}

.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;   
}

.columns div {
    margin: 0 10% 1em 10%;
    display: flex;
}

.telegram {
    margin: auto 0 auto 0;
}

a {
    opacity: 0.5;
    transition-duration: 0.75s;
}

a:hover {
    opacity: 1;
}