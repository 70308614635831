.applicationCard {
    margin: 3em 0.5em;
    width: 300px;
    height: 400px;
    border-style: none;
    border-radius: 25px;
    box-shadow: 0px 0px 20px #ccc;
    transition: 0.45s;
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.applicationCard .itemImage {
    margin-top: 2em;
    text-align: center;
}

.applicationCard .itemText {
    text-align: justify;
    vertical-align: middle;
    font-family: 'SFNS Display Thin';
    margin-left: 2.5em;
    margin-right: 2.5em;
    height: 100%;
    font-size:20px;
}

.applicationCard:hover {
    box-shadow: 0px 0px 20px #AED6F1;
}

a {
    margin: 0.3em ;
    opacity: 0.5;
    transition-duration: 0.75s;
}

a:hover {
    opacity: 1;
}

.links {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-around;
}

.applicationCard h2 {
    text-align: center;
    vertical-align: middle;
    font-family: 'SFNS Display Thin';
    color: #333;
}