.infoBlock {
    height: 100vh;
    font-family: 'SFNS Display Thin';
    font-size: 2.5em;
    background-color: #eee;
    display: flex;
    justify-content: center;
    text-align: center;
}

.mainText {
    margin: auto;
}