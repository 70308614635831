.topnav {
    position: absolute;
    top: 1em;
}

.topnav a {
    color: #333;
    height: 0.1em;
    margin: 1em;
    font-family: 'SFNS Display Thin';
    opacity: 0.5;
    text-decoration: none;
    transition-duration: 0.5s;
    padding: 0.75em;
}

.topnav a:hover {
    color: #eee;
    background-color: #333;
    opacity: 1;
}
