@media (min-width: 854px) {
    .about-content p {
        width: 45em;
    }
}

.about-block {
    display: flex;
    justify-content: center;
    text-align: center; 
    flex-direction: column;  
    margin-top: -7em; 
}

.about-content {
    font-family: 'SFNS Display Thin';
    text-align: center;
    vertical-align: middle;
    padding-top: 7em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 1em;
    background-color: #eee;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
}

.about-content p {
    margin-bottom: 1em;
    margin: auto;
}