@media (max-width: 800px) {
    .headerBlock img {
        display: none;
    }
}

@media (max-width: 1030px) {
    .headerBlock h1 {
        font-size: 1.25em;;
    }    
}

@media (min-width: 1031px) {
    .headerBlock h1 {
        font-size: 2em;;
    }    
}

.headerBlock {
    font-family: 'SFNS Display Thin';
    text-align: center;
    vertical-align: middle;
    padding-top: 2em;
    padding-bottom: 2em;
    font-size: 2.5em;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
}

.headerBlock h1 {
    color: #333;
}

.headerBlock div {
    margin:auto 0 auto 0;
}

.headerBlock p {
    width: 100%;
}