@media (max-width: 999px) {
    .contentSkills {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 1em;
    }
}

@media (min-width: 1000px) {
    .contentSkills {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 1em;
        margin: 0 17%;
    }
}